const sweatPinkColor = "#ff0d75";

window.addEventListener("scroll", function () {
  const screenHeight = window.innerHeight;
  const scrollPosition = window.scrollY;

  const desktopLogo = document.getElementById("desktop-logo");
  const mobileLogo = document.getElementById("short-logo");

  // paranoid safe
  if (desktopLogo) {
    desktopLogo.style.transition = "color 0.3s ease-in-out";
  }
  if (mobileLogo) {
    mobileLogo.style.transition = "color 0.3s ease-in-out";
  }

  if (scrollPosition > screenHeight) {
    if (desktopLogo) {
      desktopLogo.style.color = sweatPinkColor;
    }
    if (mobileLogo) {
      mobileLogo.style.color = sweatPinkColor;
    }
  } else {
    if (desktopLogo) {
      desktopLogo.style.color = "#fff";
    }
    if (mobileLogo) {
      mobileLogo.style.color = "#fff";
    }
  }
});

function replaceTexts(dataLinkSelector, updText) {
  document
    .querySelectorAll(`[data-link="${dataLinkSelector}"]`)
    .forEach(function (element) {
      element.innerText = updText;
    });
}
function updMatchedDataLinkTexts(list) {
  list.forEach(function (str) {
    replaceTexts(str.key, str.value);
  });
}

function setTextsForLocale(locale) {
  switch (locale) {
    case "ar":
      updMatchedDataLinkTexts([
        { key: "token", value: "الرمز المميَّز" },
        { key: "blog", value: "المدونة" },
        { key: "help", value: "المساعدة" },
        { key: "get-sweat-wallet", value: "الحصول على محفظة Sweat" },
        { key: "privacy-policy", value: "سياسة الخصوصية" },
        { key: "t&c", value: "الشروط والأحكام" },
        { key: "grow-terms", value: "شروط النمو" },
        { key: "bug-bounty", value: "مكافأة اكتشاف الأخطاء" },
      ]);
      break;

    case "zh":
      updMatchedDataLinkTexts([
        { key: "token", value: "代币" },
        { key: "blog", value: "博客" },
        { key: "help", value: "帮助" },
        { key: "get-sweat-wallet", value: "获取 Sweat Wallet" },
        { key: "privacy-policy", value: "隐私策略" },
        { key: "t&c", value: "条款和条件" },
        { key: "grow-terms", value: "增值条款" },
        { key: "bug-bounty", value: "漏洞奖励计划" },
      ]);
      break;

    case "ja":
      updMatchedDataLinkTexts([
        { key: "token", value: "トークン" },
        { key: "blog", value: "ブログ" },
        { key: "help", value: "ヘルプ" },
        { key: "get-sweat-wallet", value: "Sweat Wallet を入手" },
        { key: "privacy-policy", value: "プライバシーポリシー" },
        { key: "t&c", value: "利用規約" },
        { key: "grow-terms", value: "Grow の利用規約" },
        { key: "bug-bounty", value: "バグバウンティ" },
      ]);
      break;

    case "es":
      updMatchedDataLinkTexts([
        { key: "token", value: "Token" },
        { key: "blog", value: "Blog" },
        { key: "help", value: "Ayuda" },
        { key: "get-sweat-wallet", value: "Obtener Sweat Wallet" },
        { key: "privacy-policy", value: "Política de Privacidad" },
        { key: "t&c", value: "Términos y Condiciones" },
        { key: "grow-terms", value: "Términos de crecimiento" },
        { key: "bug-bounty", value: "Recompensas por errores" },
      ]);
      break;

    case "ko":
      updMatchedDataLinkTexts([
        { key: "token", value: "토큰" },
        { key: "blog", value: "블로그" },
        { key: "help", value: "도움말" },
        { key: "get-sweat-wallet", value: "Sweat Wallet 받기" },
        { key: "privacy-policy", value: "개인정보 보호정책" },
        { key: "t&c", value: "이용약관" },
        { key: "grow-terms", value: "성장 기간" },
        { key: "bug-bounty", value: "버그 현상금" },
      ]);
      break;

    default:
      return;
  }
}

const urlToUiPrefixi18nmap = {
  ko: "kp",
  zh: "cn",
  ja: "jp",
  // en: "en",
  ar: "ar",
  es: "es",
};

window.addEventListener("DOMContentLoaded", function () {
  const localeButton = document.getElementById("toggle-locale-list-button");
  const localeDropdown = document.getElementById("lang-dropdown");

  localeButton.addEventListener("click", function (e) {
    e.stopPropagation();
    localeDropdown.style.display = "flex";
  });

  document.addEventListener("click", function (e) {
    localeDropdown.style.display = "none";
  });

  const param = window.location.pathname.split("/")[1] || "en";

  const paramElement = document.getElementById(param);
  if (paramElement) paramElement.remove();
  localeButton.innerText = urlToUiPrefixi18nmap[param] || "en";

  setTextsForLocale(param);
});
